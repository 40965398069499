import React from "react"

export default () => {
  return (
    <div>
      <h2>Not Found</h2>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </div>
  )
}
